<form nz-form [formGroup]="loginModalForm">
  <nz-form-item>
    <nz-form-label nzFor="userName" nzRequired [nzSm]="6" [nzXs]="24">用户名</nz-form-label>
    <nz-form-control [nzErrorTip]="combineTpl" [nzSm]="14" [nzXs]="24">
      <input id="userName" formControlName="userName" nz-input placeholder="用户名" />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label nzFor="password" nzRequired [nzSm]="6" [nzXs]="24">密码</nz-form-label>
    <nz-form-control [nzErrorTip]="combineTpl" [nzSm]="14" [nzXs]="24">
      <input id="password" name="password" formControlName="password" nz-input placeholder="请输入密码" />
    </nz-form-control>
  </nz-form-item>
</form>

<ng-template #combineTpl let-control>
  @if (control.hasError('required')) {
    必填项
  }
</ng-template>
