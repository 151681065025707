import { inject, Injectable } from '@angular/core';

import { UserInfoKey } from '@config/constant';
import { LoginInOutService } from '@core/services/common/login-in-out.service';

import { WindowService } from '../services/common/window.service';

@Injectable({
  providedIn: 'root'
})
export class StartupService {
  private loginInOutService = inject(LoginInOutService);
  private windowSer = inject(WindowService);

  load(): Promise<void> {
    const userInfo = this.windowSer.getStorage(UserInfoKey) || {};
    if (userInfo) {
      return this.loginInOutService.loginIn(userInfo);
    }
    return new Promise(resolve => {
      return resolve();
    });
  }
}
